import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getDatabase } from '@firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStripePayments } from "@invertase/firestore-stripe-payments";

const firebaseConfig = {
    apiKey: "AIzaSyDrwAsU87B2LRAMUPjEob6tFIemQA4yIck",
    authDomain: "jobdescriptorpro.com",
    projectId: "jobdescriptor",
    storageBucket: "jobdescriptor.appspot.com",
    messagingSenderId: "1083915576570",
    appId: "1:1083915576570:web:17ccbb41a98b0e807b5543",
    measurementId: "G-JLPL9EGSFS"
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const db = getDatabase(app);
export const authentication = getAuth(app);
const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });
