import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { authentication } from '../firebase';
import { signInWithGoogle } from '../auth/signIn';
import './StepCommon.css'; // Import the common CSS


interface Step6Props {
  data: any;
  handleBack: () => void;
  handleSubmit: (event: React.FormEvent) => void; // Ensure correct type
  devMode: boolean; // Add devMode prop
}

const handleSignIn = (callback: () => void) => {
  if (authentication.currentUser) {
    console.log('Already signed in', authentication.currentUser);
    callback();
  } else {
    signInWithGoogle().then(() => {
      console.log('Signed in successfully!');
      callback();
    }).catch((error) => {
      console.error('Failed to sign in with Google:', error);
    });
  }
};

const Step6: React.FC<Step6Props> = ({ data, handleBack, handleSubmit, devMode }) => {
  const { jobId, userId, ...dataWithoutIds } = data;

  const handleFinalSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    handleSignIn(() => {
      handleSubmit(event);
    });
  };

  const generateMarkdownSummary = () => {
    let summary = '# Job Description Summary\n\n';

    const formatKey = (key: string) => {
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .replace(/Step \d+/, '')
        .trim();
    };

    for (const [key, value] of Object.entries(dataWithoutIds)) {
      if (key !== 'step') {
        const formattedKey = formatKey(key);
        if (typeof value === 'string' && value.trim() !== '') {
          summary += `### ${formattedKey}\n${value}\n\n`;
        } else if (typeof value === 'boolean') {
          summary += `### ${formattedKey}\n${value ? 'Yes' : 'No'}\n\n`;
        } else if (value !== null && value !== undefined) {
          summary += `### ${formattedKey}\n${value}\n\n`;
        }
      }
    }

    return summary;
  };

  return (
    <div className="step-container">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" align="center">
          Review and Submit
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Summary of Job Description</Typography>
        <Box sx={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid #ccc', padding: 2, borderRadius: '4px' }}>
          <ReactMarkdown>{generateMarkdownSummary()}</ReactMarkdown>
        </Box>
      </Box>
      
      <button onClick={handleBack} className="cta-button">Back</button>
      <button type="submit" onClick={handleFinalSubmit} className="cta-button">Submit</button>
    </div>
  );
};

export default Step6;