import React, { useState } from 'react';
import { Alert, Typography, Box } from '@mui/material';
import './StepCommon.css'; // Import the common CSS

interface Step5Props {
  data: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
  devMode: boolean;
}

const Step5: React.FC<Step5Props> = ({ data, handleChange, handleNext, handleBack, devMode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [failedOnce, setFailedOnce] = useState(false);

  const isValid = devMode || (
    data.weeklyHours &&
    data.onCall &&
    (data.onCall === 'No' || data.onCallDescription)
  );

  const handleNextClick = () => {
    if (isValid) {
      handleNext();
    } else {
      setShowAlert(true);
      setFailedOnce(true);
    }
  };

  return (
    <div className="step-container">
      {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)} sx={{ mb: 2 }}>
          Please fill out all required fields highlighted in red.
        </Alert>
      )}

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" align="center">
          Step 5: Overtime and Pay Structure
        </Typography>
      </Box>
      <label className={!data.weeklyHours && failedOnce ? 'highlight' : ''}>How many hours per week will this employee typically work?</label>
      <input
        type="number"
        name="weeklyHours"
        value={data.weeklyHours}
        onChange={handleChange}
        className="animated-input"
        placeholder="Enter Number of Hours"
      />
      <label className={!data.onCall && failedOnce ? 'highlight' : ''}>Is the employee required to be on-call or respond outside regular working hours?</label>
      <div>
        <label>
          <input
            type="radio"
            name="onCall"
            value="Yes"
            checked={data.onCall === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="onCall"
            value="No"
            checked={data.onCall === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.onCall === 'Yes' && (
        <>
          <label className={!data.onCallDescription && failedOnce ? 'highlight' : ''}>Describe the on-call requirements.</label>
          <textarea
            name="onCallDescription"
            value={data.onCallDescription}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter On-Call Expectations"
          ></textarea>
        </>
      )}
      <button onClick={handleNextClick} className="cta-button">Next</button>
      <button onClick={handleBack} className="cta-button">Back</button>
    </div>
  );
};

export default Step5;