import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import CreateJobDescription from './createFlow/CreateJobDescription';
import ValidateJobDescription from './validateFlow/ValidateJobDescription';
import HomePage from './HomePage';
import Header from './Header';
import Footer from './Footer';
import PricingPage from './payments/PricingPage'; // Import the new PricingPage component
import Generation from './createFlow/Generation';

function App() {
  return (
    <Router>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header />
        <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/create" element={<CreateJobDescription />} />
            <Route path="/validate" element={<ValidateJobDescription />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/generation/:jobId" element={<Generation />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
