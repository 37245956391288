import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

class StorageHandler {
  private storage = getStorage();

  async uploadFile(userId: string, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const storageRef = ref(this.storage, `jobDescriptions/${userId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Handle progress updates
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error('Error uploading file:', error);
          reject(error);
        },
        async () => {
          // Handle successful uploads
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('File available at', downloadURL);

            resolve(downloadURL);
          } catch (error) {
            console.error('Error getting download URL or adding to Firestore:', error);
            reject(error);
          }
        }
      );
    });
  }
}

export default StorageHandler;
