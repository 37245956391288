import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f8f9fa',
        padding: '1rem',
        textAlign: 'center',
        borderTop: '1px solid #e0e0e0',
        mt: 'auto', // This pushes the footer to the bottom
      }}
    >
      <Typography variant="body2" color="text.secondary">
        &copy; {new Date().getFullYear()} Job Descriptor Pro. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
