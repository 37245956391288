import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { signInWithGoogle } from './auth/signIn';


function Header() {

  const handleSignIn = () => {
    // Add your sign-in logic here
    signInWithGoogle();
  };

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Pricing', path: '/pricing' }, // New Pricing menu item
  ];

  return (
    <AppBar position="static" sx={{ background: 'linear-gradient(to right, #2c3e50, #4ca1af)' }}>
      <Toolbar sx={{ justifyContent: 'space-between', padding: 0 }}>
        <Typography variant="h6" sx={{textAlign: 'left', fontWeight: 'bold', flexGrow: 1, marginLeft: 2 }}>
          Job Descriptor Pro
        </Typography>
        <div className="desktop-menu" style={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
          {menuItems.map((item) => (
            <Button
              key={item.text}
              color="inherit"
              component={Link}
              to={item.path}
              sx={{ transition: 'color 0.3s', '&:hover': { color: '#00000' } }}
            >
              {item.text}
            </Button>
          ))}
          <Button
            color="inherit"
            onClick={handleSignIn}
            sx={{
              background: 'linear-gradient(135deg, #6a11cb, #2575fc)',
              borderRadius: '25px',
              ml: 2,
              '&:hover': { background: 'linear-gradient(135deg, #2575fc, #6a11cb)' },
              marginRight: 1
            }}
          >
            Sign In
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
