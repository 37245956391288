import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, CardActions, Container, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const PricingPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check if user has an active subscription
        const customerRef = doc(db, 'customers', user.uid);
        const customerSnap = await getDoc(customerRef);
        
        if (customerSnap.exists()) {
          const subscriptionsRef = doc(customerRef, 'subscriptions');
          const subscriptionsSnap = await getDoc(subscriptionsRef);
          
          if (subscriptionsSnap.exists()) {
            const subscriptions = subscriptionsSnap.data();
            setIsSubscribed(Object.values(subscriptions).some(sub => sub.status === 'active' || sub.status === 'trialing'));
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('success') === 'true') {
      setShowSuccessMessage(true);
      // Remove the success parameter from the URL
      navigate('/pricing', { replace: true });
    }
  }, [location, navigate]);

  const handleSubscribe = () => {
    window.location.href =  "https://buy.stripe.com/bIY28s95995e4Vy9AA";
    // window.location.href =  "https://buy.stripe.com/test_7sIeY10q5g9S8bSeUU";
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ padding: 4, textAlign: 'center' }}>
        {showSuccessMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Successfully subscribed!
          </Alert>
        )}
        <Typography variant="h4" gutterBottom>
          Job Descriptor Pro Subscription
        </Typography>
        <Card sx={{ mt: 4 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Premium Plan
            </Typography>
            <Typography variant="h4" color="text.secondary">
              $20/month
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Get access to all our premium features including unlimited job descriptions, AI-powered optimization, and priority support.
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
            {isSubscribed ? (
              <Button variant="contained" color="success" disabled>
                Currently Subscribed
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleSubscribe}>
                Subscribe Now
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default PricingPage;
