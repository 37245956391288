import React, { useEffect } from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authentication } from './firebase';
import { signInWithGoogle, handleRedirectResult } from './auth/signIn';

function NewHomePage() {
    const navigate = useNavigate();

    useEffect(() => {
        handleRedirectResult()
            .then((user) => {
                if (user) {
                    console.log('Signed in successfully!', user);
                    navigate('/create');
                }
            })
            .catch((error) => {
                console.error('Failed to handle redirect result:', error);
            });
    }, [navigate]);

    const handleSignIn = () => {
        if (authentication.currentUser) {
            console.log('Already signed in', authentication.currentUser);
            navigate('/create');
        } else {
            signInWithGoogle().catch((error) => {
                console.error('Failed to start sign-in process:', error);
            });
        }
    };
      
    return (
        <Container maxWidth="lg" sx={containerStyle}>
            <Box sx={boxStyle}>
                <Typography variant="h2" gutterBottom sx={titleStyle}>
                    Ensure Hiring Compliance with AI-Powered Job Descriptions
                </Typography>
                <Typography variant="body1" paragraph>
                    Create compliant job descriptions in minutes, adhering to federal and state labor laws
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={createButtonStyle}
                    onClick={handleSignIn}
                >
                    Create a Compliant Job Description
                </Button>
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Box sx={featureBoxStyle}>
                        <Typography variant="h4" sx={featureTitleStyle}>
                            Comprehensive Compliance
                        </Typography>
                        <Typography variant="body2" sx={featureDescriptionStyle}>
                            Ensure job descriptions comply with FLSA, ADA, ADEA, CRA 1866, and Title VII of the Civil Rights Act of 1964.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={featureBoxStyle}>
                        <Typography variant="h4" sx={featureTitleStyle} >
                            State & Local Compliance
                        </Typography>
                        <Typography variant="body2" sx={featureDescriptionStyle}>
                            Stay compliant with state and local labor laws, including pay transparency requirements.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={featureBoxStyle}>
                        <Typography variant="h4" sx={featureTitleStyle}>
                            Time-Saving & Customizable
                        </Typography>
                        <Typography variant="body2" sx={featureDescriptionStyle}>
                            Create professional, compliant job descriptions in minutes, tailored to your company's unique needs.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <Box sx={{ mt: 10, mb: 4, textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom sx={titleStyle}>
                    Coming Soon
                </Typography>
            
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={comingSoonBoxStyle}>
                            <Typography variant="h4" sx={featureTitleStyle}>
                                ATS Integration
                            </Typography>
                            <Typography variant="body2" sx={featureDescriptionStyle}>
                                Seamlessly integrate with your favorite Applicant Tracking Systems for a streamlined hiring process.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={comingSoonBoxStyle2}>
                            <Typography variant="h4" sx={featureTitleStyle}>
                                Enhanced Compliance Features
                            </Typography>
                            <Typography variant="body2" sx={featureDescriptionStyle}>
                                Additional compliance checks for ADA, ADEA, CRA 1866, Title VII, and state-specific labor laws.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default NewHomePage;

// Style objects
const containerStyle = {
    py: 5,
};

const boxStyle = {
    background: 'linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)',
    borderRadius: 2,
    p: 4,
    textAlign: 'center',
    boxShadow: 3,
    mb: 4,
};

const buttonStyle = {
    mt: 2,
    borderRadius: '25px',
    transition: 'transform 0.3s, box-shadow 0.3s',
};

const createButtonStyle = {
    ...buttonStyle,
    background: 'linear-gradient(135deg, #6a11cb, #2575fc)',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        background: 'linear-gradient(135deg, #2575fc, #6a11cb)',
    },
};

const featureBoxStyle = {
    background: 'linear-gradient(to bottom, #f6f6f6 0%, #e9e9e9 100%)',
    borderRadius: 2,
    p: 3,
    pb: 0,
    textAlign: 'center',
    boxShadow: 3,
    transition: 'transform 0.3s, box-shadow 0.3s',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: 6,
    },
    '& > :first-of-type': {
        mb: 2, // Add margin bottom to the title
    },
};

const featureTitleStyle = {
    mb: 1, // Reduced margin bottom
};

const comingSoonBoxStyle = {
    ...featureBoxStyle
    
 ,
 background: 'linear-gradient(350deg, #8e7cc3 0%, #c2e9fb 100%)',
};

const comingSoonBoxStyle2 = {
    ...featureBoxStyle
    
 ,
 background: 'linear-gradient(70deg, #8e7cc3 0%, #c2e9fb 100%)',
};

const titleStyle = {
    fontSize: {
        xs: '2rem',
        sm: '2.5rem',
        md: '3rem',
    },
    lineHeight: 1.2,
    overflowWrap: 'break-word',
    hyphens: 'auto',
};
const featureDescriptionStyle = {
    color: '#666', // Change this to your desired color
    mt: '0', // Push the description to the bottom
};

