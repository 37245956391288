import { GoogleAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { authentication } from '../firebase';

// Initialize Firebase Auth and provider
const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
    return signInWithRedirect(authentication, provider);
};

export const handleRedirectResult = () => {
    return getRedirectResult(authentication)
        .then((result) => {
            if (result) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                // The signed-in user info.
                const user = result.user;
                return user;
            }
            return null;
        }).catch((error) => {
            // Handle Errors here.
            console.error("Authentication error", error);
            throw error;
        });
};

// export const signInWithGoogleRedirect = () => {
//     return signInWithRedirect(authentication, provider);
// };
