import { db } from '../firebase';
import { ref, set, onValue } from "firebase/database";
import { authentication } from '../firebase'; // Import authentication

class JobDescriptionDAO {
	private userId: string;

	constructor() {
		const currentUser = authentication.currentUser;
		this.userId = currentUser?.uid || '';
	}

	// Method to write job description data
	writeJobDescription(jobId: string, data: any): void {
		const jobRef = ref(db, `jobDescriptions/${this.userId}/${jobId}`);
		set(jobRef, data).then(() => {
			console.log(`${this.userId} saved job description for ${jobId}`);
		}).catch((error) => {
			console.error("Error saving job description: ", error);
		});
	}

	// Method to read job description data
	readJobDescription(jobId: string, success: (data: any) => void): () => void {
		const jobRef = ref(db, `jobDescriptions/${this.userId}/${jobId}`);
		const unsubscribe = onValue(jobRef, (snapshot) => {
			if (snapshot.exists()) {
				console.log("Retrieved job description", snapshot.val());
				success(snapshot.val());
			}
		}, (error) => {
			console.error("Error retrieving job description: ", error);
		});
		return unsubscribe;
	}

	// unused
	// Method to clean up job description
	cleanUpJobDescription(jobId: string): void {
		const jobRef = ref(db, `jobDescriptions/${this.userId}/${jobId}`);
		set(jobRef, null).then(() => {
			console.log(`Cleaned up job description for ${this.userId} and job ${jobId}`);
		}).catch((error) => {
			console.error("Error cleaning up job description: ", error);
		});
	}
}

export default JobDescriptionDAO;
