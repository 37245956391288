// job-description/src/Step3.tsx
import React, { useState } from 'react';
import { Alert, Typography, Box } from '@mui/material';
import './StepCommon.css'; // Import the common CSS

interface Step3Props {
  data: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
  devMode: boolean;
}

const Step3: React.FC<Step3Props> = ({ data, handleChange, handleNext, handleBack, devMode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [failedOnce, setFailedOnce] = useState(false);

  const isValid = devMode || (
    data.primaryDuties &&
    data.independentJudgment &&
    data.manualLabor &&
    (data.manualLabor === 'No' || data.laborDescription)
  );

  const handleNextClick = () => {
    if (isValid) {
      handleNext();
    } else {
      setShowAlert(true);
      setFailedOnce(true);
    }
  };

  return (
    <div className="step-container">
      {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)} sx={{ mb: 2 }}>
          Please fill out all required fields highlighted in red.
        </Alert>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" align="center">
          Step 3: Job Responsibilities and Duties
        </Typography>
      </Box>
      <label className={!data.primaryDuties && failedOnce ? 'highlight' : ''}>What are the primary duties of this job?</label>
      <textarea
        name="primaryDuties"
        value={data.primaryDuties}
        onChange={handleChange}
        className="animated-input"
        placeholder="Enter Primary Job Duties"
      ></textarea>
      <label className={!data.independentJudgment && failedOnce ? 'highlight' : ''}>Does this role involve exercising independent judgment or decision-making authority?</label>
      <div>
        <label>
          <input
            type="radio"
            name="independentJudgment"
            value="Yes"
            checked={data.independentJudgment === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="independentJudgment"
            value="No"
            checked={data.independentJudgment === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.independentJudgment === 'Yes' && (
        <>
          <label>Provide examples of decisions made without supervision.</label>
          <textarea
            name="decisionExamples"
            value={data.decisionExamples}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter Decision-Making Examples"
          ></textarea>
        </>
      )}
      <label className={!data.manualLabor && failedOnce ? 'highlight' : ''}>Is manual labor or repetitive work a significant part of this role?</label>
      <div>
        <label>
          <input
            type="radio"
            name="manualLabor"
            value="Yes"
            checked={data.manualLabor === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="manualLabor"
            value="No"
            checked={data.manualLabor === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.manualLabor === 'Yes' && (
        <>
          <label className={!data.laborDescription && failedOnce ? 'highlight' : ''}>Describe the type of manual labor or repetitive tasks.</label>
          <textarea
            name="laborDescription"
            value={data.laborDescription}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter Description of Manual Labor"
          ></textarea>
        </>
      )}
      <button onClick={handleNextClick} className="cta-button">Next</button>
      <button onClick={handleBack} className="cta-button">Back</button>
    </div>
  );
};

export default Step3;