// job-description/src/Step2.tsx
import React, { useState } from 'react';
import { Alert, Typography, Box } from '@mui/material';
import './StepCommon.css'; // Import the common CSS

interface Step2Props {
  data: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
  devMode: boolean;
}

const Step2: React.FC<Step2Props> = ({ data, handleChange, handleNext, handleBack, devMode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [failedOnce, setFailedOnce] = useState(false);

  const isValid = devMode || (
    data.payBasis &&
    data.salaryOrWage &&
    data.workHours &&
    (data.workHours === 'No' || data.overtimeEligible) &&
    data.supervisoryDuties &&
    (data.supervisoryDuties === 'No' || data.employeesSupervised) &&
    data.specializedKnowledge
  );

  const handleNextClick = () => {
    if (isValid) {
      handleNext();
    } else {
      setShowAlert(true);
      setFailedOnce(true);
    }
  };

  return (
    <div className="step-container">
      {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)} sx={{ mb: 2 }}>
          Please fill out all required fields highlighted in red.
        </Alert>
      )}

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" align="center">
          Step 2: Job Classification (Exempt vs. Non-Exempt)
        </Typography>
      </Box>
      <label className={!data.payBasis && failedOnce ? 'highlight' : ''}>Is this position paid on a salary or hourly basis?</label>
      <div>
        <label>
          <input
            type="radio"
            name="payBasis"
            value="Salary"
            checked={data.payBasis === 'Salary'}
            onChange={handleChange}
          />
          Salary
        </label>
        <label>
          <input
            type="radio"
            name="payBasis"
            value="Hourly"
            checked={data.payBasis === 'Hourly'}
            onChange={handleChange}
          />
          Hourly
        </label>
      </div>
      <label className={!data.salaryOrWage && failedOnce ? 'highlight' : ''}>What is the employee's expected annual salary or hourly wage?</label>
      <input
        type="text"
        name="salaryOrWage"
        value={data.salaryOrWage}
        onChange={handleChange}
        className="animated-input"
        placeholder="Enter Salary or Hourly Wage"
      />
      <label className={!data.workHours && failedOnce ? 'highlight' : ''}>Will this role regularly involve more than 40 hours of work per week?</label>
      <div>
        <label>
          <input
            type="radio"
            name="workHours"
            value="Yes"
            checked={data.workHours === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="workHours"
            value="No"
            checked={data.workHours === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.workHours === 'Yes' && (
        <>
          <label className={!data.overtimeEligible && failedOnce ? 'highlight' : ''}>Is the employee eligible for overtime pay?</label>
          <div>
            <label>
              <input
                type="radio"
                name="overtimeEligible"
                value="Yes"
                checked={data.overtimeEligible === 'Yes'}
                onChange={handleChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="overtimeEligible"
                value="No"
                checked={data.overtimeEligible === 'No'}
                onChange={handleChange}
              />
              No
            </label>
          </div>
        </>
      )}
      <label className={!data.supervisoryDuties && failedOnce ? 'highlight' : ''}>Does this position include any supervisory or management duties?</label>
      <div>
        <label>
          <input
            type="radio"
            name="supervisoryDuties"
            value="Yes"
            checked={data.supervisoryDuties === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="supervisoryDuties"
            value="No"
            checked={data.supervisoryDuties === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.supervisoryDuties === 'Yes' && (
        <>
          <label className={!data.employeesSupervised && failedOnce ? 'highlight' : ''}>How many employees will they supervise?</label>
          <input
            type="text"
            name="employeesSupervised"
            value={data.employeesSupervised}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter Number of Employees Supervised"
          />
        </>
      )}
      <label className={!data.specializedKnowledge && failedOnce ? 'highlight' : ''}>Does this position require specialized knowledge or advanced education?</label>
      <div>
        <label>
          <input
            type="radio"
            name="specializedKnowledge"
            value="Yes"
            checked={data.specializedKnowledge === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="specializedKnowledge"
            value="No"
            checked={data.specializedKnowledge === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.specializedKnowledge === 'Yes' && (
        <>
          <label className={!data.qualifications && failedOnce ? 'highlight' : ''}>Please specify the required qualifications or certifications.</label>
          <input
            type="text"
            name="qualifications"
            value={data.qualifications}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter Qualifications or Certifications"
          />
        </>
      )}
      <button onClick={handleNextClick} className="cta-button">Next</button>
      <button onClick={handleBack} className="cta-button">Back</button>
    </div>
  );
};

export default Step2;