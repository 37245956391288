import React, { useState } from 'react';
import { Alert, Typography, Box } from '@mui/material';
import './StepCommon.css'; // Import the common CSS

interface Step1Props {
  data: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  devMode: boolean;
}

const Step1: React.FC<Step1Props> = ({ data, handleChange, handleNext, devMode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [failedOnce, setFailedOnce] = useState(false);


  const isValid = devMode || (data.jobTitle && data.department);

  const handleNextClick = () => {
    if (isValid) {
      handleNext();
    } else {
      console.log('Invalid form data');
      setShowAlert(true);
      setFailedOnce(true);
    }
  };

  return (
    <div className="step-container">
      {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)} sx={{ mb: 2 }}>
          Please fill out all required fields highlighted in red.
        </Alert>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" align="center">
          Step 1: Basic Job Information
        </Typography>
      </Box>
      <label className={!data.jobTitle && failedOnce ? 'highlight' : ''}>Job Title:</label>
      <input
        type="text"
        name="jobTitle"
        value={data.jobTitle}
        onChange={handleChange}
        className="animated-input"
        placeholder="Enter Job Title"
      />
      <label className={!data.department && failedOnce ? 'highlight' : ''}>Department/Team:</label>
      <input
        type="text"
        name="department"
        value={data.department}
        onChange={handleChange}
        className="animated-input"
        placeholder="Enter Department/Team"
      />
      <label>Is this a new or existing role?</label>
      <div>
        <label>
          <input
            type="radio"
            name="roleType"
            value="New"
            checked={data.roleType === 'New'}
            onChange={handleChange}
          />
          New
        </label>
        <label>
          <input
            type="radio"
            name="roleType"
            value="Existing"
            checked={data.roleType === 'Existing'}
            onChange={handleChange}
          />
          Existing
        </label>
      </div>
      <button onClick={handleNextClick} className="cta-button" >Next</button>
    </div>
  );
};

export default Step1;
