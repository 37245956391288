import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import JobDescriptionDAO from '../database/JobDescriptionDAO';
import ReactMarkdown from 'react-markdown';
import './Generation.css'; // Import the CSS file for the spinner
import { Box, Typography, Paper } from '@mui/material';
import { authentication } from '../firebase'; // Import authentication

const Generation: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const [jobDescription, setJobDescription] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('pending');
  const [jobDescriptionDAO] = useState(() => new JobDescriptionDAO());

  useEffect(() => {
    if (jobId) {
      // Update the URL to include the jobId
      navigate(`/generation/${jobId}`, { replace: true });

      const unsubscribe = jobDescriptionDAO.readJobDescription(jobId, (data) => {
        setJobDescription(data.description);
        setStatus(data.status);
      });

      return () => unsubscribe();
    }
  }, [jobId, navigate, jobDescriptionDAO]);

  if (status !== 'complete') {
    return (
      <Box className="loading-container" sx={{ 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <div className="spinner"></div>
        <Typography variant="h6" sx={{ mt: 2 }}>Generating your job description...</Typography>
      </Box>
    );
  }

  return (
    <Box className="generation-container" sx={{ padding: 3, maxWidth: '800px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Finished Job Description
      </Typography>
      <Paper elevation={3} sx={{ padding: 3, overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
        <ReactMarkdown>{jobDescription || ''}</ReactMarkdown>
      </Paper>
    </Box>
  );
};

export default Generation;
