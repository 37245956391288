import React, { Component, createRef } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import StorageHandler from '../storage/StorageHandler';

interface State {
  loading: boolean;
  complianceReport: string | null;
  userId: string;
}

class ValidateJobDescription extends Component<{}, State> {
  private fileInputRef = createRef<HTMLInputElement>();
  private storageHandler = new StorageHandler();

  constructor(props: {}) {
    super(props);
    const userId = uuidv4();
    this.state = {
      loading: false,
      complianceReport: null,
      userId,
    };
  }

  handleFileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (!file) return;

    this.uploadFile(file);
  };

  handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    this.uploadFile(file);
  };

  uploadFile = (file: File) => {
    this.setState({ loading: true });

    this.storageHandler.uploadFile(this.state.userId, file)
      .then((downloadURL) => {
        console.log('File available at', downloadURL);
        this.setState({ complianceReport: 'File uploaded successfully.', loading: false });
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        this.setState({ loading: false });
      });
  };

  handleClick = () => {
    this.fileInputRef.current?.click();
  };

  render() {
    const { loading, complianceReport } = this.state;

    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Validate Job Descriptions
        </Typography>
        <Box
          onDragOver={(e) => e.preventDefault()}
          onDrop={this.handleFileDrop}
          onClick={this.handleClick}
          sx={dropZoneStyle}
        >
          <CloudUpload sx={{ fontSize: 48, color: '#6a11cb' }} />
          <Typography variant="body1" sx={{ mt: 2, color: '#555' }}>
            Drag and drop your job description document here
          </Typography>
          <Typography variant="body2" sx={{ color: '#888' }}>
            or click to select a file
          </Typography>
        </Box>
        <input
          type="file"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          onChange={this.handleFileSelect}
        />

        {loading && (
          <Box sx={{ mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Uploading document, please wait...
            </Typography>
          </Box>
        )}

        {complianceReport && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Upload Status</Typography>
            <Typography variant="body1">{complianceReport}</Typography>
          </Box>
        )}
      </Box>
    );
  }
}

export default ValidateJobDescription;

// Style for the drop zone
const dropZoneStyle = {
  width: '100%',
  maxWidth: '600px',
  height: '250px',
  border: '2px dashed #6a11cb',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  mt: 4,
  cursor: 'pointer',
  transition: 'background-color 0.3s, border-color 0.3s',
  '&:hover': {
    backgroundColor: '#f9f9f9',
    borderColor: '#2575fc',
  },
};
