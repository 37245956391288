import React, { useState } from 'react';
import { Alert, Typography, Box } from '@mui/material';
import './StepCommon.css'; // Import the common CSS

interface Step4Props {
  data: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
  devMode: boolean;
}

const Step4: React.FC<Step4Props> = ({ data, handleChange, handleNext, handleBack, devMode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [failedOnce, setFailedOnce] = useState(false);

  const isValid = devMode || (
    data.physicalLabor &&
    data.uniqueConditions &&
    (data.physicalLabor === 'No' || data.physicalDemands) &&
    (data.uniqueConditions === 'No' || data.workEnvironment)
  );

  const handleNextClick = () => {
    if (isValid) {
      handleNext();
    } else {
      setShowAlert(true);
      setFailedOnce(true);
    }
  };

  return (
    <div className="step-container">
      {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)} sx={{ mb: 2 }}>
          Please fill out all required fields highlighted in red.
        </Alert>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" align="center">
          Step 4: Work Environment and Conditions
        </Typography>
      </Box>
      <label className={!data.physicalLabor && failedOnce ? 'highlight' : ''}>Does this job require physical labor, such as lifting or standing for long periods?</label>
      <div>
        <label>
          <input
            type="radio"
            name="physicalLabor"
            value="Yes"
            checked={data.physicalLabor === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="physicalLabor"
            value="No"
            checked={data.physicalLabor === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.physicalLabor === 'Yes' && (
        <>
          <label className={!data.physicalDemands && failedOnce ? 'highlight' : ''}>Please describe the physical demands.</label>
          <textarea
            name="physicalDemands"
            value={data.physicalDemands}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter Physical Demands Description"
          ></textarea>
        </>
      )}
      <label className={!data.uniqueConditions && failedOnce ? 'highlight' : ''}>Are there any unique working conditions?</label>
      <div>
        <label>
          <input
            type="radio"
            name="uniqueConditions"
            value="Yes"
            checked={data.uniqueConditions === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="uniqueConditions"
            value="No"
            checked={data.uniqueConditions === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      {data.uniqueConditions === 'Yes' && (
        <>
          <label className={!data.workEnvironment && failedOnce ? 'highlight' : ''}>Please describe the working conditions.</label>
          <textarea
            name="workEnvironment"
            value={data.workEnvironment}
            onChange={handleChange}
            className="animated-input"
            placeholder="Enter Work Environment Description"
          ></textarea>
        </>
      )}
      <button onClick={handleNextClick} className="cta-button">Next</button>
      <button onClick={handleBack} className="cta-button">Back</button>
    </div>
  );
};

export default Step4;