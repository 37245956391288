import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Generation from './Generation';
import '../App.css';
import JobDescriptionDAO from '../database/JobDescriptionDAO';

const CreateJobDescription: React.FC = () => {
  const navigate = useNavigate();
  const [jobDescriptionDAO] = useState(() => new JobDescriptionDAO());

  const [state, setState] = useState({
    step: 1,
    jobId: uuidv4(),
    jobTitle: '',
    department: '',
    roleType: '',
    payBasis: '',
    salaryOrWage: '',
    workHours: '',
    overtimeEligible: '',
    supervisoryDuties: '',
    employeesSupervised: '',
    specializedKnowledge: '',
    qualifications: '',
    primaryDuties: '',
    independentJudgment: '',
    decisionExamples: '',
    manualLabor: '',
    laborDescription: '',
    physicalLabor: '',
    physicalDemands: '',
    uniqueConditions: '',
    workEnvironment: '',
    weeklyHours: '',
    onCall: '',
    onCallDescription: '',
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }, []);

  const handleNext = useCallback(() => {
    setState(prevState => ({ ...prevState, step: prevState.step + 1 }));
  }, []);

  const handleBack = useCallback(() => {
    setState(prevState => ({ ...prevState, step: prevState.step - 1 }));
  }, []);

  const handleSubmit = useCallback(async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Form Data:', state);
    const { step, jobId, ...formattedData } = state;

    const updatedData = { ...formattedData, status: 'pending' };

    // Commit to Firebase using DAO
    await jobDescriptionDAO.writeJobDescription(jobId, updatedData);
    navigate(`/generation/${jobId}`); // Navigate to Generation component with jobId
  }, [state, jobDescriptionDAO, navigate]);

  const renderStep = () => {
    const { step, jobId } = state;
    const devMode = true; // Set devMode to true for development

    switch (step) {
      case 1:
        return <Step1 data={state} handleChange={handleChange} handleNext={handleNext} devMode={devMode} />;
      case 2:
        return <Step2 data={state} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} devMode={devMode} />;
      case 3:
        return <Step3 data={state} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} devMode={devMode} />;
      case 4:
        return <Step4 data={state} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} devMode={devMode} />;
      case 5:
        return <Step5 data={state} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} devMode={devMode} />;
      case 6:
        return <Step6 data={state} handleBack={handleBack} handleSubmit={handleSubmit} devMode={devMode} />;
      case 7:
        return <Generation />;
      default:
        return <Step1 data={state} handleChange={handleChange} handleNext={handleNext} devMode={devMode} />;
    }
  };

  return (
    <div className="create-job-description-container">
      {renderStep()}
    </div>
  );
};

export default CreateJobDescription;